<template>
  <div class="gg-container">
    <div class="search-container">
      <div class="search-container-fn-input">
        <label>账号/姓名</label>
        <el-input
          size="mini"
          placeholder="账号/姓名"
          prefix-icon="el-icon-search"
          v-model="searchParams.keyword"
          clearable
          @change="getList('rest')"
        >
        </el-input>
      </div>

      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>

        <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>

        <div class="search-container-fn-input" v-if="operateList.indexOf('add') > -1">
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="editItem('add')"
          >
            <i class="el-icon-plus" style="font-size: 13px" />
            新增</el-button
          >
        </div>

        <div class="search-container-fn-input" v-if="operateList.indexOf('update') > -1">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="editItem('edit')"
            >编辑
          </el-button>
        </div>

        <div class="search-container-fn-input" v-if="operateList.indexOf('update_pwd') > -1">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
				                      background-color: #2362FB; border-color: #2362FB"
            @click="handlerChangePwd('rest')"
            >修改密码</el-button
          >
        </div>

        <div class="search-container-fn-input" v-if="operateList.indexOf('delete') > -1">
          <el-button
            type="danger"
            size="mini"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px"
            @click="handlerDelete"
          >
            <i class="el-icon-delete" style="font-size: 13px" />
            删除</el-button
          >
        </div>
      </div>

      <!--<div class="search-container-fn-input">-->
      <!--	<label>账号状态：</label>-->
      <!--	<el-select size="small" v-model="searchParams.status" placeholder="请选择" clearable>-->
      <!--		<el-option class="depart-class" v-for="item in statusOptions" :key="item.value" :label="item.label"-->
      <!--			:value="item.value">-->
      <!--		</el-option>-->
      <!--	</el-select>-->
      <!--</div>-->

      <!--<div class="search-container-fn-input">-->
      <!--	<label>账号角色：</label>-->
      <!--	<el-select size="small" v-model="searchParams.role_id" placeholder="请选择" clearable>-->
      <!--		<el-option-group v-for="group in groupRoles" :key="group.label" :label="group.label">-->
      <!--			<el-option v-for="item in group.options" :key="item.id" :label="item.role_name"-->
      <!--				:value="item.id">-->
      <!--			</el-option>-->
      <!--		</el-option-group>-->
      <!--	</el-select>-->
      <!--</div>-->
    </div>
    <div class="search-container">
      <!-- <div class="search-container-fn-input">
				<label>账号类型：</label>
				<el-select v-model="searchParams.type" placeholder="请选择" clearable>
					<el-option class="depart-class" v-for="item in typeOptions" :key="item.value" :label="item.label"
						:value="item.value">
					</el-option>
				</el-select>
			</div> -->

      <!--<div class="search-container-fn-input">-->
      <!--	<label>真实姓名/手机号：</label>-->
      <!--	<el-input size="small" placeholder="真实姓名/手机号" prefix-icon="el-icon-search" v-model="searchParams.name"-->
      <!--		clearable @change="getList('rest')">-->
      <!--	</el-input>-->
      <!--</div>-->

      <!--<div class="search-container-fn-input">-->
      <!--	<label>创建时间：</label>-->
      <!--	<el-date-picker v-model="searchParams.created_at" size="small" type="datetimerange" range-separator="至"-->
      <!--		start-placeholder="开始日期" end-placeholder="结束日期" clearable format="yyyy-MM-dd"-->
      <!--		value-format="yyyy-MM-dd" align="left">-->
      <!--	</el-date-picker>-->
      <!--</div>-->
    </div>

    <!--<el-table v-loading="listLoading" :data="tableData" row-key="id" border style="width: 100%">-->

    <!--	<el-table-column prop="id" label="账号id" width="80" align="center">-->
    <!--	</el-table-column>-->

    <!--	<el-table-column align="center" width="200" prop="username" label="账号" >-->
    <!--	</el-table-column>-->
    <!--	<el-table-column prop="name" label="真实姓名" align="center">-->
    <!--	</el-table-column>-->
    <!--	&lt;!&ndash;<el-table-column prop="mobile" width="150" label="手机号" align="center">&ndash;&gt;-->
    <!--	&lt;!&ndash;</el-table-column>&ndash;&gt;-->
    <!--	<el-table-column prop="role_name" label="账号角色" align="center">-->
    <!--	</el-table-column>-->

    <!--	<el-table-column prop="status_cn" label="账号状态" align="center">-->
    <!--		<template slot-scope="{row}">-->
    <!--			&lt;!&ndash; <el-tag :type="row.status==1?'success':'danger'">{{getStatus(row.status)}}</el-tag> &ndash;&gt;-->
    <!--			<el-switch @change="changeStatus($event,row)" v-model="row.status" class="switch"-->
    <!--					:active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用"-->
    <!--					active-color="#13ce66" inactive-color="#C8C8C8">-->
    <!--				</el-switch>-->
    <!--		</template>-->
    <!--	</el-table-column>-->
    <!--	&lt;!&ndash;<el-table-column prop="created_at" label="创建时间" width="220" align="center">&ndash;&gt;-->
    <!--	&lt;!&ndash;</el-table-column>&ndash;&gt;-->
    <!--	&lt;!&ndash;            <el-table-column&ndash;&gt;-->
    <!--	&lt;!&ndash;                    prop="quick_reply"&ndash;&gt;-->
    <!--	&lt;!&ndash;                    label="排序">&ndash;&gt;-->
    <!--	&lt;!&ndash;                <template slot-scope="{row}">&ndash;&gt;-->
    <!--	&lt;!&ndash;                    <span v-if="row.id==12"><i class="dot"></i>已禁用</span>&ndash;&gt;-->
    <!--	&lt;!&ndash;                    <span v-if="row.id==11" class="text-success"><i class="dot"></i>可用</span>&ndash;&gt;-->
    <!--	&lt;!&ndash;                </template>&ndash;&gt;-->
    <!--	&lt;!&ndash;            </el-table-column>&ndash;&gt;-->
    <!--	<el-table-column prop="address" label="操作" width="200" align="center">-->
    <!--		<template slot-scope="{row}">-->
    <!--			<el-button style="padding: 7px 8px;" @click="editItem('edit',row)" type="primary" size="mini" icon="el-icon-edit">编辑</el-button>-->
    <!--			<el-button style="padding: 7px 8px;margin-left: 5px;" @click="handlerChangePwd(row)" type="success" size="mini" icon="el-icon-edit-outline">修改密码</el-button>-->
    <!--			&lt;!&ndash; <el-button :type="row.status==1?'danger':'primary'" size="mini" @click="adminStatus(row)"-->
    <!--				:disabled="statusDis">{{row.status==1?'禁用':'启用'}}</el-button> &ndash;&gt;-->
    <!--		</template>-->
    <!--	</el-table-column>-->
    <!--</el-table>-->

    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="625"
      style="width: 100%;z-index:0"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <el-table-column type="selection" width="55" align="center" />
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <!--<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="hover">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
									<i style="margin-left: 5px" class="search-icon el-icon-search"></i>
								</div>
								<el-input
										size="mini"
										placeholder=""
										prefix-icon="el-icon-search"
										v-model="searchParams.buy_count"
										clearable
										@change="getList('restPage')"
									>
									</el-input>
							</el-popover>
						</template>-->
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handleCustomerDetail(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>

        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              v-if="'status' == column.column_prop"
              class="switch"
              @change="changeStatus(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>

            <el-switch
              v-if="'show_mobile_status' == column.column_prop"
              class="switch"
              @change="changeExamineShowMobileStatus(scope.row)"
              v-model="scope.row.show_mobile_status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row[column.column_prop].length > 0"
              style="font-size: 12px; color: #2379fb"
              @click="
                handleShowImage(
                  scope.row[column.column_prop],
                  scope.row[column.column_prop][0]
                )
              "
            >
              <a>点击查看图片</a>
              <el-image
                style="width: 0px; height: 0px"
                :ref="scope.row[column.column_prop][0]"
                :src="imageUrl"
                :preview-src-list="scope.row[column.column_prop]"
                :z-index="9999"
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-input
                v-if="'order_count' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.buy_count"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.buy_count)
                "
              >
              </el-input>

              <el-input
                v-if="'mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.mobile"
                clearable
                @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>

              <el-select
                v-else-if="'agent_name' == column.column_prop"
                style="width:200px"
                v-model="searchParams.agent_id"
                placeholder="请选择"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.agent_id)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in merchantList"
                  :key="item.value"
                  :label="item.merchant_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <el-select
                v-else-if="'visit_level' == column.column_prop"
                style="width:200px"
                v-model="searchParams.visit_level"
                placeholder="请选择"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.visit_level)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in optionsVisitLevel"
                  :key="item.short_name"
                  :label="item.short_name"
                  :value="item.short_name"
                >
                </el-option>
              </el-select>

              <el-select
                v-else-if="'from' == column.column_prop"
                style="width:200px"
                v-model="searchParams.from_id"
                placeholder="请输入客户来源"
                size="small"
                clearable
                @change="handleSearch(column.column_prop, searchParams.from_id)"
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in customerFromOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <el-date-picker
                v-else-if="'created_at' == column.column_prop"
                v-model="choiceDateCreateRecord"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH-mm-ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleCreateRecordChangeDate()"
              >
              </el-date-picker>

              <el-select
                v-else-if="'staff_name' == column.column_prop"
                style="width:200px"
                v-model="searchParams.staff_name"
                placeholder="请选择"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.staff_name)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in staffOptions"
                  :key="item.id"
                  :label="item.staff_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
          <template slot-scope="scope">
            <span v-if="column.column_prop === 'buy_status'">{{
              scope.row[column.column_prop] === 1 ? "未购" : "已购"
            }}</span>
            <span v-else-if="column.column_prop === 'use_status'">
              {{ scope.row[column.column_prop] | useStatusFormat }}
            </span>
            <span v-else-if="column.column_prop === 'is_wx'">
              {{ scope.row[column.column_prop] == "0" ? "否" : "是" }}
            </span>
            <span v-else-if="column.column_prop === 'supervise_status'">
              {{ scope.row[column.column_prop] | formatSuperviseStatus }}
            </span>
            <span v-else>{{ scope.row[column.column_prop] }}</span>
          </template>
        </el-table-column>
      </template>

      <!--<el-table-column label="操作" align="center">-->
      <!--	<template slot-scope="scope">-->
      <!--		<el-button size="mini" type="primary" icon="el-icon-edit"-->
      <!--							 style="padding: 6px 7px; margin-right: 10px;-->
      <!--                      background-color: #2362FB; border-color: #2362FB"-->
      <!--							 @click="handleEdit(scope.row)">编辑-->
      <!--		</el-button>-->
      <!--		<el-button size="mini" type="danger" icon="el-icon-delete"-->
      <!--							 style="padding: 6px 7px; "-->
      <!--							 @click="handleDelete(scope.row)">删除-->
      <!--		</el-button>-->
      <!--		-->
      <!--	</template>-->
      <!--</el-table-column>-->
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--新增编辑 快捷回复-->
    <el-dialog title="修改密码" :visible.sync="revisePSD" v-if="revisePSD">
      <el-form
        label-width="80px"
        :model="passWord"
        style="margin-top: 30px;"
        ref="rulePassword"
        :rules="rulePassword"
      >
        <el-form-item label="密码" prop="psd">
          <el-input v-model="passWord.psd"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="qrpsd">
          <el-input v-model="passWord.qrpsd"></el-input>
        </el-form-item>
      </el-form>
      <div style="display: flex;justify-content: center;margin-top: 30px;">
        <el-button type="success" size="mini" @click="savePassword"
          >保存</el-button
        >
        <el-button size="mini" @click="revisePSD = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="60%"
      v-if="dialogVisible"
    >
      <el-form
        ref="ruleForm"
        :model="currInfo"
        label-width="130px"
        :rules="rules"
        class="demo-ruleForm"
        style="margin-top: 50px;"
      >
        <el-form-item
          label="真实姓名"
          prop="name"
          maxlength="20"
          show-word-limit
        >
          <el-input v-model="currInfo.name"></el-input>
        </el-form-item>

        <el-form-item label="账号" prop="username">
          <template v-if="openType == 'add'">
            <el-input
              v-model="currInfo.username"
              maxlength="20"
              show-word-limit
            ></el-input>
          </template>
          <template v-else>
            {{ currInfo.username }}
          </template>
        </el-form-item>

        <el-form-item label="密码" prop="password" v-if="openType == 'add'">
          <template>
            <el-input v-model="currInfo.password"></el-input>
          </template>
        </el-form-item>

        <el-form-item label="类型" prop="type" v-if="is_admin == '1'">
          <template v-if="openType == 'add'">
            <el-radio-group v-model="currInfo.type" @change="handleAuthType">
              <el-radio-button
                style="margin-right:15px;"
                v-for="item in typeOptions"
                :label="item.value"
                :key="item.value"
                >{{ item.label }}</el-radio-button
              >
            </el-radio-group>
          </template>
          <template v-else>
            {{ getTypeName(currInfo.type) }}
          </template>
        </el-form-item>
        <el-form-item
          label="机构"
          prop="agent_id"
          v-if="is_admin == '1' && currInfo.type == 2"
        >
          <template v-if="openType == 'add'">
            <el-select
              v-model="currInfo.agent_id"
              placeholder="请选择"
              @change="handleSelectAgent($event)"
            >
              <el-option
                :class="['depart-class', item.p_id == 0 ? '' : 'two_class']"
                v-for="item in agents"
                :key="item.id"
                :label="item.merchant_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
          <template v-else>
            {{ currInfo.merchant_name }}
          </template>
        </el-form-item>

        <el-form-item
          label="药师"
          prop="phar_id"
          v-if="is_admin == '1' && currInfo.type == 3"
        >
          <template v-if="openType == 'add'">
            <el-select v-model="currInfo.phar_id" placeholder="请选择">
              <el-option
                class="depart-class"
                v-for="item in phars"
                :key="item.id"
                :label="item.doctor_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
          <!-- <template v-else>
						{{currInfo.merchant_name}}
					</template> -->
        </el-form-item>

        <el-form-item label="角色" prop="role_id">
          <el-select
            v-model="currInfo.role_id"
            placeholder="请选择"
            @change="changeRole($event)"
          >
            <el-option
              class="depart-class"
              v-for="item in roles"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注">
          <el-input v-model="currInfo.remark" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="mini"
          @click="saveReply('ruleForm')"
          :disabled="disabled"
          >保存
        </el-button>
      </span>
    </el-dialog>

    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import {
  getAccountList,
  putAccountStatus,
  getRolesList,
  putAccountPwd,
  delAccount,
  putStatus,
} from "@/api/user";

import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import editTableHead from "@/components/editTableHead/editTableHead";
import request from "@/utils/request";
import globalData from "@/utils/globalData.js";
import { mapState } from "vuex";
import { getAdminFieldIndex } from "@/api/auth/tableAuth";
export default {
  name: "account",
  components: {
    Pagination,
    editTableHead,
  },
  computed: {
    getTypeName() {
      return (type) => {
        console.log(111111111, type, this.typeOptions);
        let curType = this.typeOptions.find((v) => v.value == type);
        return curType ? curType.label : "";
      };
    },

    getStatus() {
      return (status) => {
        let curStatus = this.statusOptions.find((v) => v.value == status);
        return curStatus ? curStatus.label : "";
      };
    },
    dialogTitle() {
      return (
        {
          add: "添加账号",
          edit: "编辑账号",
        }[this.openType] || "添加账号"
      );
    },
    ...mapState({
      is_admin: (state) => state.user.is_admin,
      operates: (state) => state.user.operates,
      agents: (state) => state.auth.agents,
      table_options: state => state.user.table_options,
    }),
    getRoleType() {
      return () => {
        let curRole = this.roles.find((v) => v.id == this.currInfo.role_id);
        return curRole ? curRole.type : 0;
      };
    },
  },
  async created() {
    console.log(this.$route.path);
    console.log(this.operates);
    this.operates.forEach((operate) => {
      if (operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(","));
        this.operateList.push(...operate[this.$route.path].split(","));
      }
    });
    await this._getAdminFieldIndex();
    this.getRoles();
    this.getList();
    this.handleAuthType();
  },

  data() {
    return {
      table_type: 'accountManagement',
      searchTableHead: [
        {
          // name: 'recovery_count',
          // isSearch: false
        },
      ],
      tableHeadSortable: ["created_at"],
      tableHead: [],
      choiceDateCreateRecord: [],
      isActiveEditTableHead: false,
      passWord: {
        psd: "",
        qrpsd: "",
      },

      rulePassword: {
        psd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          // {
          // 	min: 8,
          // 	max: 20,
          // 	message: '密码必须由大小写字母+数字+特殊字符组合且8位以上',
          // 	trigger: 'blur',
          // 	validator: (rule, value, callback) => {
          // 		if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,}$/
          // 			.test(value) == false) {
          // 			callback(new Error("用户名由4-20位字母组成"));
          // 		} else {
          // 			callback();
          // 		}
          // 	}
          // }
        ],
        qrpsd: [
          {
            required: true,
            message: "请确认密码",
            trigger: "blur",
          },
          // {
          // 	min: 8,
          // 	max: 20,
          // 	message: '密码必须由大小写字母+数字+特殊字符组合且8位以上',
          // 	trigger: 'blur',
          // 	validator: (rule, value, callback) => {
          // 		if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,}$/
          // 			.test(value) == false) {
          // 			callback(new Error("用户名由4-20位字母组成"));
          // 		} else {
          // 			callback();
          // 		}
          // 	}
          // }
        ],
      },
      statusDis: false,
      showResetButton: true,
      groupRoles: [],
      revisePSD: false,
      statusOptions: [
        {
          label: "启用",
          value: 1,
        },
        {
          label: "禁用",
          value: 0,
        },
      ],
      roles: [],
      pharmacy_room: [],
      disabled: false,
      typeOptions: globalData.typeOptions,
      top_depart: [],
      searchParams: {
        username: "",
        name: "",
      },
      currInfo: {
        name: "",
        type: 1,
        role_id: "",
        agent_id: "",
      },
      dialogVisible: false,
      replyContent: "",
      openType: "",
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      ruleForm: {
        name: "",
        code: "",
        sort: "",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "长度在 2 到 20 个字符",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入真实姓名",
            trigger: "blur",
          },
        ],

        role_id: [
          {
            required: true,
            message: "请选择角色",
            trigger: "change",
          },
        ],
        agent_id: [
          {
            required: true,
            message: "请选择机构",
            trigger: "change",
          },
        ],
        phar_id: [
          {
            required: true,
            message: "请选择药师",
            trigger: "change",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          // {
          // 	min: 8,
          // 	max: 20,
          // 	message: '密码必须由大小写字母+数字+特殊字符组合且8位以上',
          // 	trigger: 'blur',
          // 	validator: (rule, value, callback) => {
          // 		if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,}$/
          // 			.test(value) == false) {
          // 			callback(new Error("用户名由4-20位字母组成"));
          // 		} else {
          // 			callback();
          // 		}
          // 	}
          // }
        ],
        ad_expand: [
          {
            required: true,
            message: "请输入工号",
            trigger: "blur",
          },
        ],
        account_no: [
          {
            required: true,
            message: "请输入分机号",
            trigger: "blur",
          },
        ],
      },
      curAccountInfo: {},
      multipleSelection: [],
      operateList: [],
    };
  },
  mounted() {
    this.$store.dispatch("auth/getAgents");
    this.$store.dispatch("auth/getPahrs");
  },
  methods: {
    changeRole(e) {
      let curRole = this.roles.find((v) => v.id == e);
    },
    handleSelectAgent(e) {
      console.log(11, e);
      this.currInfo.role_id = "";
      this.currInfo.agent_id = e;

      this.getRoles(e);
    },

    handleAuthType() {
      console.log(999);
      this.currInfo.role_id = "";
      this.$set(this.currInfo, "agent_id", "");

      this.getRoles(0);
      this.pharmacist = [];
      this.pharmacy_room = [];
    },
    handlerChangePwd(row) {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "error",
          message: "只能单个编辑!",
        });
        return;
      } else if (this.multipleSelection.length == 0) {
        this.$message({
          type: "error",
          message: "请选择!",
        });
        return;
      }
      this.passWord = {};
      this.revisePSD = true;
      this.curAccountInfo = this.multipleSelection[0];
    },
    savePassword() {
      this.$refs["rulePassword"].validate(async (valid) => {
        console.log(valid);
        if (!valid) {
          return;
        } else {
          console.log(this.passWord.psd, this.passWord.qrpsd);
          if (this.passWord.psd !== this.passWord.qrpsd) {
            this.$message({
              type: "error",
              message: "两次密码不一致",
            });
          } else {
            var data = {
              password: this.passWord.psd,
            };
            var res = await putAccountPwd(this.curAccountInfo.id, data);
            console.log(res);
            if (res.code == 200) {
              this.revisePSD = false;
              this.$message.success("修改成功！");
            }
            return;
          }
        }
      });
    },
    handleReset() {
      this.showResetButton = false;
    },
    getRoles(agent_id = 0) {
      console.log("请求接口");
      getRolesList({ agent_id, type: this.currInfo.type }).then((res) => {
        if (res.code == 200) {
          this.roles = res.data;
          this.groupRoles = this._getGroupRoles(this.roleList);
          console.log("this.groupRoles", this.groupRoles);
        }
      });
    },
    _getGroupRoles(roles) {
      console.log("this.typeOptions", this.typeOptions);
      let role = [];
      if (roles) {
        for (let index in roles) {
          let curType = this.typeOptions.find((v) => v.value == index + 1);
          console.log(curType);
          role.push({
            label: curType.label,
            options: [roles[index]],
          });
        }
      }
      return role;
    },
    getList(type) {
      if (type == "rest") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = Object.assign({}, this.searchParams);
      params.page = this.listQuery.page;
      params.limit = this.listQuery.limit;
      getAccountList(params)
        .then((response) => {
          let data = response;
          console.log("data", data);
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },

    editItem(type, item) {
      console.log(type, item);
      this.openType = type;
      if (type === "add") {
        this.currInfo = {
          role_id: "",
          type: 1,
        };
      } else {
        if (this.multipleSelection.length > 1) {
          this.$message({
            type: "error",
            message: "只能单个编辑!",
          });
          return;
        } else if (this.multipleSelection.length == 0) {
          this.$message({
            type: "error",
            message: "请选择!",
          });
          return;
        }

        this.showResetButton = true;
        this.currInfo = Object.assign({}, this.multipleSelection[0]);
        if (this.$store.state.user.is_admin == 1) {
          this.getRoles(this.currInfo.agent_id);
        }
        this.agents.forEach((agent) => {
          if (agent.id == this.currInfo.agent_id) {
            this.currInfo.merchant_name = agent.merchant_name;
          }
        });
      }
      this.dialogVisible = true;
    },
    saveReply(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          return;
        } else {
          let url = "";
          let method = "";
          let curAgent = this.agents.find(
            (v) => v.id == this.currInfo.agent_id
          );
          if (curAgent) {
            this.currInfo.agent_name = curAgent.merchant_name;
          }
          console.log(111111111, this.currInfo);
          var data = JSON.parse(JSON.stringify(this.currInfo));
          if (this.openType == "add") {
            url = "admin/users"; //添加
            method = "post";
            request({
              url,
              method,
              data: data,
            })
              .then((response) => {
                let data = response;
                if (data.code == 200) {
                  if (this.openType == "add") {
                    // this.getRoles()
                  }
                  this.$message({
                    type: "success",
                    message: this.dialogTitle + "成功！",
                  });
                  this.getList();
                  this.dialogVisible = false;
                } else {
                  this.$message({
                    type: "error",
                    message: data.msg ? data.msg : data.message,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            //编辑
            url = `admin/users/` + this.currInfo.id;
            method = "put";
            request({
              url,
              method,
              data: data,
            })
              .then((response) => {
                let data = response;
                if (data.code == 200) {
                  this.$message({
                    type: "success",
                    message: this.dialogTitle + "成功！",
                  });
                  this.getList();
                  this.dialogVisible = false;
                } else {
                  this.$message({
                    type: "error",
                    message: data.msg ? data.msg : data.message,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      });
    },

    //启用禁用按钮
    changeStatus(row) {
      // let params = {
      // 	status: e
      // }
      // var status = row.status == 1 ? 0 : 1
      console.log(row.status);
      putAccountStatus(row.id, { status: row.status })
        .then((res) => {
          if (res.code == 200) {
            // var msg = e == 1 ? '已启用' : '已禁用'
            // 	return this.$message.success(msg)
          } else {
            return this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      // console.log(e)
    },

    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    handleResetSearch() {
      this.searchParams = {
        type: "buy",
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },
   _getAdminFieldIndex() {
        this.listLoading = true;
        // var params = {
        //   type: "accountManagement",
        // };
        // const res = await getAdminFieldIndex(params);
        console.log('动态表头',this.table_options)
        this.tableHead = this.table_options.accountManagement;
        console.log(this.tableHead);
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      val.forEach((item) => {
        this.multipleSelection.push(item);
      });
    },
    //启动开关
    changeExamine(event) {
      this.switchDis = true;
      console.log(event);
      let data = {
        status: Number(event.status),
      };
      putStatus(event.id, data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getList();
        } else {
          this.$message({
            message: "修改失败",
            type: "error",
          });
        }
      });
    },
    handlerDelete() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "error",
          message: "只能单个编辑!",
        });
        return;
      } else if (this.multipleSelection.length == 0) {
        this.$message({
          type: "error",
          message: "请选择!",
        });
        return;
      }
      this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //确定删除
          delAccount(this.multipleSelection[0].id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style>
.el-radio-group .el-radio-button__inner {
  border: 1px solid #dcdfe6;
}
</style>
<style scoped lang="scss">
.depart-class {
  padding-left: 35px;
}

.two_class {
  margin-left: 15px;
}

.icon-depart {
  display: inline-block;
  margin: 0 10px;
  color: #409eff;
}

.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}
/* switch按钮样式 */
::v-deep.switch {
  .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
    text-align: center;
  }

  /*打开时文字位置设置*/
  .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .el-switch__label--right span {
    margin-right: 11px;
  }

  /*关闭时文字位置设置*/
  .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .el-switch__label--left span {
    margin-left: 11px;
  }

  /*显示文字*/
  .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .el-switch__core,
  .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>

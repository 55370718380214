export default {
	typeOptions: [{
			label: '平台',
			value: 1,
		},
		{
			label: '机构',
			value: 2,
		}
	]
}
